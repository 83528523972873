<template>
  <div style="background-color: #f3f3f4;">
    <div style="max-width: 1200px;margin: 0 auto;">
      <div class="wrapper wrapper-content gray-bg">
        <div class="row animated rollIn">
          <!-- 顶站栏 -->
          <div class="col-sm-12" style="background-color: #ffffff;">
            <div style="max-width: 1170px;">
              <div style="height: 20px;width:100%;"></div>
              <div style="display: flex;flex-direction:row;justify-content:space-between; width: 100%;">
                <div style="height:72px;display:flex;flex-direction:column;justify-content:center;align-items:center;">
                  <div style="margin-left: 48px;">
                    <!-- <img src="../../public/images/iemcu_logo_72.png" alt="" style="width: 48px;height:48px;"> -->
                    <img src="../../public/images/iemcu_logo_305x83.png" alt="" style="height:56px;">
                  </div>
                </div>
                <div style="flex-grow:1;font-size: 28px;line-height: 54px;height: 54px;margin-left: 20px;margin-top: 10px;">
                  <!-- Insight Ecosys -->
                </div>
                <div v-if="editEnable" style="width: 64px;height:64px;display:flex;flex-direction:column;justify-content:center;align-items:center;color: #c3c3c3;font-size: 12px;" @click="onClickAdd">
                  <i class="fa fa-plus-circle fa-2x"></i>
                </div>
                <div v-if="mode == 'admin'" style="width: 64px;height:64px;display:flex;flex-direction:column;justify-content:center;align-items:center;color: #c3c3c3;font-size: 12px;" @click="onClickLogin">
                  <i class="fa fa-user fa-2x"></i>
                </div>
                <div style="width: 20px;"></div>
              </div>
            </div>
          </div>
          <!-- Timeline内容区 -->
          <div class="col-sm-12">
            <div v-if="infoList.length == 0" style="min-height: 1200px;background-color: #ffffff;">
              <el-empty :image-size="200" description="暂无信息，感谢您的关注！！！"></el-empty>
            </div>
            <div v-else class="ibox float-e-margins">
              <div class="ibox-content" id="ibox-content">
                <div id="vertical-timeline" class="vertical-container dark-timeline">
                  <div v-for="(item, index) in infoList" :key="index" class="vertical-timeline-block">
                    <div class="vertical-timeline-icon iemcu-bg1">
                      <i v-if="item.icon != ''" :class="item.icon"></i>
                      <i v-else class="fa fa-exclamation"></i>
                    </div>
                    <div class="vertical-timeline-content">
                      <div style="display: flex;flex-direction:row;justify-content:space-between;font-size: 18px;line-height: 18px;">
                        <div style="flex: 1;">
                          {{item.title}}
                        </div>
                        <div v-if="editEnable && item.status == 0" @click="onClickDisable(index)" style="color: #21ba45;width: 32px;">
                          <i class="fa fa-toggle-on"></i>
                        </div>
                        <div v-if="editEnable && item.status != 0" @click="onClickEnable(index)" style="color: #c3c3c3;width: 32px;">
                          <i class="fa fa-toggle-off"></i>
                        </div>
                        <div v-if="editEnable" @click="onClickEdit(index)" style="color: #c3c3c3;width: 32px;">
                          <i class="fa fa-edit"></i>
                        </div>
                      </div>
                      <div>
                        <p style="white-space: pre-wrap;">{{item.detail}}</p>
                      </div>


                      <el-link v-if="item.url != ''" :href="item.url" type="primary" target="_blank" style="float: right;">更多...</el-link>
                      <!-- <a v-if="item.url != ''" href="#" class="btn btn-sm btn-primary"> 更多...</a> -->
                      <span class="vertical-date">
                        <small>{{getDateStr(item.insert_time)}}</small>
                      </span>
                    </div>
                  </div>
                  <!--                                     
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon navy-bg">
                          <i class="fa fa-briefcase"></i>
                      </div>
                      <div class="vertical-timeline-content">
                          <h2>Meeting</h2>
                          <p>Conference on the sales results for the previous year. Monica please
                              examine sales trends in marketing and products. Below please find the
                              current status of the sale.
                          </p>
                          <a href="#" class="btn btn-sm btn-primary"> More info</a>
                          <span class="vertical-date">
                              Today <br />
                              <small>Dec 24</small>
                          </span>
                      </div>
                  </div>
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon blue-bg">
                          <i class="fa fa-file-text"></i>
                      </div>
                      <div class="vertical-timeline-content">
                          <h2>Send documents to Mike</h2>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since.</p>
                          <a href="#" class="btn btn-sm btn-success"> More info </a>
                          <span class="vertical-date">
                              Today <br />
                              <small>Dec 24</small>
                          </span>
                      </div>
                  </div>
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon lazur-bg">
                          <i class="fa fa-coffee"></i>
                      </div>
                      <div class="vertical-timeline-content">
                          <h2>Coffee Break</h2>
                          <p>Go to shop and find some products. Lorem Ipsum is simply dummy text of
                              the printing and typesetting industry. Lorem Ipsum has been the
                              industry's. </p>
                          <a href="#" class="btn btn-sm btn-info">Read more</a>
                          <span class="vertical-date"> Yesterday <br /><small>Dec 23</small></span>
                      </div>
                  </div>
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon yellow-bg">
                          <i class="fa fa-phone"></i>
                      </div>

                      <div class="vertical-timeline-content">
                          <h2>Phone with Jeronimo</h2>
                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio,
                              dolorum provident rerum aut hic quasi placeat iure tempora laudantium
                              ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                          <span class="vertical-date">Yesterday <br /><small>Dec 23</small></span>
                      </div>
                  </div>
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon lazur-bg">
                          <i class="fa fa-user-md"></i>
                      </div>

                      <div class="vertical-timeline-content">
                          <h2>Go to the doctor dr Smith</h2>
                          <p>Find some issue and go to doctor. Lorem Ipsum is simply dummy text of the
                              printing and typesetting industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s. </p>
                          <span class="vertical-date">Yesterday <br /><small>Dec 23</small></span>
                      </div>
                  </div>
                  <div class="vertical-timeline-block">
                      <div class="vertical-timeline-icon navy-bg">
                          <i class="fa fa-comments"></i>
                      </div>
                      <div class="vertical-timeline-content">
                          <h2>Chat with Monica and Sandra</h2>
                          <p>Web sites still in their infancy. Various versions have evolved over the
                              years, sometimes by accident, sometimes on purpose (injected humour and
                              the like). </p>
                          <span class="vertical-date">Yesterday <br /><small>Dec 23</small></span>
                      </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <div class="footer fixed">
        <div class="pull-right" style="padding-right: 20px;">
          <a href="https://beian.miit.gov.cn/">苏ICP备2022041488号</a>
        </div>
        <div style="padding-left: 20px;">
          Copyright &copy; 2022-2023 iemcu.com
        </div>
      </div>
    </div>
    <!-- 编辑框 begin -->
    <el-dialog title="编辑信息" :visible.sync="visibleDialogEdit">
      <el-form :model="dialogEditForm">
        <el-form-item label="图标" :label-width="formLabelWidth" prop='icon'>
          <el-popover placement="bottom" width="450" trigger="click" @show="popoverShow">
            <span slot="reference">
              <el-input suffix-icon='data-icon' v-model='dialogEditForm.icon' placeholder='请输入'>
                <template slot="append"><i :class="dialogEditForm.icon"/></template>
              </el-input>
            </span>
            <div class="iconList">
              <i class="icon_list_item" v-for="item in iconList" :key="item" :class="item" @click="setIcon(item)" style="font-size:20px"></i>
            </div>
          </el-popover>
          <!-- <IconPicker v-model="dialogEditForm.icon" placement="bottom-left" size="40" width="1000"></IconPicker> -->
        </el-form-item>
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="dialogEditForm.title" autocomplete="off" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" placeholder="请输入内容(暂不支持多行)">
          <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 4}" v-model="dialogEditForm.detail" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="网址" :label-width="formLabelWidth">
          <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 4}" v-model="dialogEditForm.url" autocomplete="off" placeholder="请输入以http://或https://开头的网址"></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-upload action="#" :http-request="uploadPict" list-type="picture-card" :auto-upload="true" :file-list="dialogEditForm.uploadPicList"> 
            <i slot="default" class="el-icon-plus" style="line-height: 98px;"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" width="100px" height="100px">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="handleRemovePict(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="时间" :label-width="formLabelWidth">
          <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 4}" v-model="dialogEditForm.insert_time" autocomplete="off" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="visibleDialogEdit = false">取 消</el-button>
        <el-button type="primary" @click="onClickDialogEditOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 登录 -->
    <el-dialog title="登录" :visible.sync="visibleDialogFormLogin" width="500px" modal center style="">
      <el-form :model="formLogin" size="mini" style="margin: 0 60px;">
        <el-form-item label="用户" label-width="40px">
          <el-input v-model="formLogin.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="40px">
          <el-input type="password" v-model="formLogin.password" autocomplete="off" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="visibleDialogFormLogin = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onClickLoginOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑框 end -->
  </div>
</template>
<script>
import md5 from 'js-md5'
export default {
  data() {
    return {
      loginInfo: null,
      visibleDialogFormLogin: false,
      formLogin: {
        username: '',
        password: ''
      },
      activeName: 'first',
      iconList: [
        "fa fa-exclamation",
        "fa fa-flag",
        "fa fa-slack",
        "fa fa-arrows-alt",
        "fa fa-video-camera",
        "fa fa-cutlery",
        "fa fa-keyboard-o",
        "el-icon-folder",
        "el-icon-warning-outline",
        "el-icon-aim",
        "fa fa-list-ol",
        "el-icon-pie-chart",
        "el-icon-crop",
        "el-icon-zoom-out",
        "fa fa-cab"
      ],
      editEnable: false,
      name: 'iemcu',
      editIndex: -1,
      visibleDialogEdit: false,
      formLabelWidth: '40px',
      dialogEditForm: {
        id: 0,
        icon: '',
        title: '',
        detail: '',
        url: '',
        insert_time: '2022-10-19 23:22:43',
        uploadPicList: []
      },
      infoList: [],
      mode: '',
      dialogPreviewImageUrl: '',
      dialogPreviewVisible: false
    }
  },
  mounted() {
    
  },
  created() {
    console.log('route.params', this.$route.params)
    if (this.$route.params.mode == 'admin') {
      this.mode = this.$route.params.mode
    }
    this.loadTimelineData()
  },
  computed: {

  },
  methods: {
    getDateStr(str) {
      return str.substr(0,10);
    },
    replaceRN(str) {
      str = str.replace(/\r\n/g,"<br>")
      str = str.replace(/\n/g,"<br>");
      return str
    },
    handleRemovePict(file) {
      console.log(this.dialogEditForm.uploadPicList)
      console.log(file)
    },
    uploadPict(params) {
      console.log(params)
    },
    onClickDialogEditOk() {
      this.changeMessage(this.dialogEditForm)
    },
    onClickEnable(index) {
      this.infoList[index].status = 0 
      console.log('onClickEnable', this.infoList[index])
      this.changeMessage(this.infoList[index])
    },
    onClickDisable(index) {
      this.infoList[index].status = 1
      console.log('onClickDisable', this.infoList[index])
      this.changeMessage(this.infoList[index])
    },
    changeMessage(info) {
      let that = this
      this.$axios({
        method: 'post',
        url: 'api/www/timeline/change',
        data: {
          id: info.id,
          status: info.status,
          title: info.title,
          icon: info.icon,
          detail: info.detail,
          url: info.url,
          insert_time: info.insert_time
        },
        headers: {
          authorization: "Bearer " + that.loginInfo.JWT
        }
      }).then(function (res) {
        console.log(res)
        that.visibleDialogEdit = false
        that.loadTimelineDataAll()
      }).catch(function(err){
        console.error(err)
        that.$message.error(err)
      })
    },
    login() {
      let that = this
      this.$axios({
        method: 'post',
        url: 'api/www/auth/login',
        data: {
          username: this.formLogin.username,
          md5pwd: md5(this.formLogin.password)
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          console.log(res)
          that.loginInfo = res.data.data
          that.loadTimelineDataAll()
          that.editEnable = true
          that.visibleDialogFormLogin = false
        } else {
          console.error(res.data)
          that.$message.error(res.data.message)
        }
      }).catch(function(err){
        console.error(err)
        that.$message.error(err)
      })
    },
    loadTimelineData() {
      let that = this
      this.$axios({
        method: 'post',
        url: 'api/www/timeline/index',
        data: {}
      }).then(function (res) {
        console.log(res)
        that.infoList = res.data
      }).catch(function(err){
        console.error(err)
        that.$message.error(err)
      })
    },
    loadTimelineDataAll() {
      let that = this
      console.log("loginInfo", that.loginInfo)
      this.$axios({
        method: 'post',
        url: 'api/www/timeline/all',
        data: {},
        headers: {
          authorization: "Bearer " + that.loginInfo.JWT
        }
      }).then(function (res) {
        console.log(res)
        that.infoList = res.data
      }).catch(function(err){
        console.error(err)
        that.$message.error(err)
      })
    },
    onClickLogin() {
      this.visibleDialogFormLogin = true
    },
    onClickLoginOk() {
      console.log('username', this.formLogin.username)
      console.log('md5pwd', md5(this.formLogin.password))
      this.login()
    },
    onClickAdd() {
      this.editIndex = -1
      this.dialogEditForm = {
        id: 0,
        static: 0,
        icon: 'fa fa-exclamation',
        title: '',
        detail: '',
        url: '',
        insert_time: this.formatDate(new Date(),'yyyy-MM-dd hh:mm:ss')
      },
      this.visibleDialogEdit = true
    },
    onClickEdit(idx) {
      this.editIndex = idx
      console.log(idx)
      this.dialogEditForm = this.infoList[idx]
      if (this.dialogEditForm.uploadPicList === undefined) {
        this.dialogEditForm.uploadPicList = []
      }
      if (this.dialogEditForm.icon == '') {
        this.dialogEditForm.icon = 'fa fa-exclamation'
      } 
      this.visibleDialogEdit = true
    },
    popoverShow() {
      this.$nextTick(()=>{
        this.activeName = '0'
      })
    },
    setIcon(icon) {
      this.$set(this.dialogEditForm, 'icon', icon)
    },
    formatDate(date, fmt) {
      //获取年份
      if (/(y+)/.test(fmt)) {
        // 把数字变成字符串
        let dateY = date.getFullYear() + ""
        //RegExp.$1 在判断中出现过，且是括号括起来的，所以 RegExp.$1 就是 "yyyy"
        fmt = fmt.replace(RegExp.$1, dateY.substr(4 - RegExp.$1.length))
      }
      //获取其他
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      }
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ""
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    }
  },
}
</script>
<!-- <style scoped src="../../public/font-awesome/css/font-awesome.css"></style> -->
<!-- <style scoped src="../../public/css/plugins/iCheck/custom.css"></style>
<style scoped src="../../public/css/animate.css"></style>
<style scoped src="../../public/css/style.css"></style> -->
<!-- <style lang="scss" scoped></style> -->
<style scoped>
.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

a {
    text-decoration: none;
}
a:visited {
  color: #2c3e50;
}
.wrapper-content {
  font-size: 13px;
  margin: 0 auto;
  
}
:after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/deep/ .el-upload--picture-card{
    width: 100px;
    height: 100px;
}
/deep/ .el-upload{
    width: 100px;
    height: 100px;
    line-height: 98px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
    width: 100px;
    height: 100px;
    line-height: 98px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    width: 100px;
    height: 100px;
    line-height: 98px;
}
/* /deep/ .avatar{
    width: 100px;
    height: 100px;
} */

.gray-bg {
    background-color: #f3f3f4;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.ibox {
  max-width: 1200px;
  margin: 0 auto;
    clear: both;
    /* margin-bottom: 25px;
    margin-top: 0; */
    padding: 0;
}

.ibox:after, .ibox:before {
    display: table;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    /* padding: 15px 20px 20px 20px; */
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
    display: block;
    text-align: left;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
    line-height: 1.42857143;
    -webkit-text-size-adjust: 100%;
    min-height: 1800px;
}

.iemcu-bg1{
  background-color: #ff8000;
  color: #ffffff;
}
.navy-bg {
  background-color: #1ab394;
  color: #ffffff;
}
.float-e-margins .btn {
    margin-bottom: 5px;
}
.btn-primary {
    background-color: #1ab394;
    border-color: #1ab394;
    color: #FFFFFF;
}
.btn {
    border-radius: 3px;
}
.btn-group-sm>.btn, .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
/* New Timeline style */
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 14px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}
.vertical-timeline-content .btn {
  float: right;
}
#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}
.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}
.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent transparent transparent #f5f5f5;
}
.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}
.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5;
}
@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}
@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}
.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-block:first-child {
  margin-top: 0;
}
.vertical-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }
  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }
  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 12px;
  border: 3px solid #f1f1f1;
  text-align: center;
  box-sizing: border-box;
}
.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -6px;
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }
  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }
  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}
.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}
.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}
.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}
.vertical-date small {
  color: #1ab394;
  font-weight: 400;
}
.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }
  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}

.vertical-timeline-content h2 {
    font-weight: 400;
    margin-top: 4px;
}


.ibox-content h1, .ibox-content h2, .ibox-content h3, .ibox-content h4, .ibox-content h5, .ibox-title h1, .ibox-title h2, .ibox-title h3, .ibox-title h4, .ibox-title h5 {
    margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
      font-size: 18px;
  }
}
.footer {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 14px;
}

.footer.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px 0px;
    background: white;
    border-top: 1px solid #e7eaec;
    /* margin-left: 220px; */
}


.icon_list {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /*一定要换行 */
  
  ::v-deep .el-tabs__content {
    height: 270px;
    overflow-y: scroll; /*y轴显示滚动条*/
    overflow-x: hidden; /*x轴溢出隐藏*/
  }
}

.icon_list_item {
  display: inline-block;
    width: 60px;
    height: 45px;
    color: #000000;
    font-size: 20px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    margin: 5px;
}

.icon_list_item:hover {
  color: blue;
  border-color: blue;
}



</style>
