import Vue from 'vue'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../public/font-awesome/css/font-awesome.css'
import App from './App.vue'

Vue.use(ElementUI)
// 安装路由
import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
