import Vue from 'vue'
import Router from 'vue-router'
import index from '@/pages/index'
// import Welcome from '@/pages/welcome'
// import HelloWorld from '@/components/HelloWorld'

Vue.use(Router)

export default new Router({
  // mode: 'history', //去掉链接的#号
  base: '/', //去掉链接的/杠
  // 路由的懒加载
  routes: [
    {// 重定向
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index/:mode?',
      name: 'index',
      component: index
    },
    // {
    //     path: '/welcome',
    //     name: 'welcome',
    //     component: Welcome
    //   },
    // {
    //   path: '/HelloWorld',
    //   name: 'hello',
    //   component: HelloWorld
    // },
  ]
})
